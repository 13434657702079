.GridImage {
    position: relative;
    padding: 0.5em;
    margin: 0.25em;
}

.GridImage div.img-container {
    position: relative;
    top: 0;
    left: 0;
}

img {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
}

img.annotation-overlay {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
}

img.class-overlay {
    position: absolute;
    width: 40%;
    height: 40%;
    right: 0;
    margin-left: auto;
    z-index: 1;
}

p.image-name {
    word-break: break-all;
}

.hidden {
    display: none;
}