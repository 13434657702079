div.AnalyticDetails {
    width: 100%;
}

div.AnalyticDetails img.nav-link-icon {
    max-width: 15%;
    margin-left: 0.5em;
}

div.AnalyticDetails div.analytic-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

div.AnalyticDetails div.analytic-details-container > * {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    width: 50%;
    border: 1px solid black;
    background-color: lightgray;
}

div.AnalyticDetails div.refresh-container {
    display: flex;
    justify-content: left;
}

div.AnalyticDetails div.analytic-status {
    padding: 30px;
    font-size: 1.5em;
}

div.AnalyticDetails form.analytic-patch-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
}

div.AnalyticDetails form.analytic-patch-form input {
    width: 40px;
    height: 20px;
    margin-bottom: 20px;
    font-size: 1.2em;
}

div.AnalyticDetails div.mark-abandoned-container {
    background-color: white;
    padding: 20px;
}

div.AnalyticDetails div.mark-abandoned-container button {
    margin: 0 10px;
    border: 0.5px solid black;
    background-color: #ffdae1;
    padding: 10px;
    font-size: 1.2em;
}