div.Jobs {
    width: 100%;
    align-items: center;
}

div.Jobs img.nav-link-icon {
    max-width: 30%;
}

div.Jobs div.top-container {
    display: flex;
}

div.Jobs div.manage-images-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

div.Jobs div.job-info-container {
    flex: 6;
}

div.Jobs div.job-type-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

div.Jobs div.job-type-container .job-type-button {
    padding: 20px;
    background-color: aliceblue;
    border: 0.5px solid black;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100px;
    width: 300px;
}

div.Jobs img.job-icon {
    width: 50px;
    height: 50px;
    flex: 1;
}

div.Jobs p.job-type-label {
    flex: 3;
    font-size: 1.5em;
}

div.Jobs div.selected {
    border: 0.25px solid gray;
    background-color: lightyellow;
}

div.Jobs div.unclickable {
    opacity: 0.5;
}

div.Jobs div.controls-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
}

div.Jobs div.controls-container > * {
    flex: 1;
    flex-basis: auto;
}

div.Jobs div.controls-container button,
div.Jobs div.manage-images-container button {
    margin: 0 10px;
    background-color: aliceblue;
    border: 0.5px solid black;
    padding: 10px;
    font-size: 1em;
}

div.Jobs .manage-images-value {
    margin: 0 10px;
    margin-top: 1em;
    padding: 10px;
    font-size: 1em;
    border: 1px solid black;
    border-radius: 30% / 50%;
    background-color: #EEE;
}

div.Jobs div.annotation-and-review-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20%;
    margin-right: 20%;
}

div.Jobs div.annotation-and-review-container > * {
    flex: 1;
    flex-basis: auto;
    margin: 0 10px;
}

div.Jobs div.annotation-and-review-container .annotation-and-review-button {
    padding-left: 100px;
    padding-right: 100px;
}

div.Jobs div.annotation-and-review-n-images-per-status {
    margin-top: 0.25em;
    margin: 10px;
    padding: 0.25em;
    display: flex;
    flex-direction: row;
    border: 0.25px solid gray;
    border-radius: 10% / 80%;
}

div.Jobs div.annotation-and-review-value-label {
    flex: 2;
}

div.Jobs div.annotation-and-review-value {
    flex: 1;
}

div.Jobs div.do-fine-tuning-container {
    display: flex;
    flex-direction: column;
    margin-right: 0.25em;
    padding: 10px;
    border: 1px solid black;
    margin-bottom: 1em;
}

div.Jobs div.do-fine-tuning-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5em;
}

div.Jobs div.job-complete-container {
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
}

div.Jobs .job-complete-button {
    margin-bottom: auto;
}

div.Jobs .job-complete-value {
    margin: 0 10px;
    margin-top: 1em;
    padding: 10px;
    border: 1px solid black;
    border-radius: 30% / 50%;
}

div.Jobs div.top-controls-container {
    display: flex;
    justify-content: center;
}

div.Jobs div.refresh-container {
    flex: 1;
    display: flex;
    justify-content: left;
}

div.Jobs div.checkbox-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

div.Jobs div.checkbox-container label {
    display: block;
    text-align: center;
    line-height: 150%;
}

div.Jobs div.checkbox-container input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 5px;
    border: 2px solid #555; 
    margin-right: 30px;
}

div.Jobs div.show-excluded input[type="checkbox"]:checked {
    background: pink;
}

div.Jobs div.annotation-count-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em;
}

div.Jobs div.annotation-count {
    border: 2px solid #555; 
    margin-right: 30px;
    padding: 5px;
}

div.Jobs div.weed {
    background: #ffe0f0;
}

div.Jobs div.crop {
    background: #a6f3ed;
}