div.login-mode-container {
    padding: 10px;
}

div.login-mode-container button.login-mode-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
}