body {
  margin: 0;
  margin-bottom: 50px;
}
  
.App {
  text-align: center;
}

div.menu {
  display: flex;
  background-color: lightgray;
  color: #888;  /*Colors box shadow */
  box-shadow: 0 0 0.3em 0.05em;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
}

div.menu #menu-logo {
  max-height: 50px;
  margin: 0 1em;
  cursor: pointer;
}

div.menu #menu-user {
  cursor: pointer;
}

div.menu div.link-holder {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;

  /* Sets the margin to get smaller as the window gets smaller */
  margin-right: calc(0.18 * 100%);
}

div.menu a.Link {
  color: #636363;
  align-content: center;
  display: inline-block;
  margin: 0;
  padding: 0 1em;
  text-decoration: none;
  text-align: center;
}

div.menu a.Link.hidden {
  padding: 0;
  display: none;
}

div.menu a.Link:hover {
  background-color: #636363;
  color: #D4D4D4;
  transition: 200ms;
}

h2::first-letter {
  text-transform: capitalize;
}

.no-capitalize::first-letter {
  text-transform: none;
}

div.App .button {
  cursor: pointer;
  padding: 1em;
  border: 1px solid #333;
  border-radius: 0.25em;
  font-size: small;
  background-color: #EEE;
}

div.App .button:hover {
  background-color: #E5E5E5;
  box-shadow: 0 0 1em -0.7em;
}

.cursor-pointer {
  cursor: pointer;
}

.copy-pointer {
  cursor: copy;
}

div.App .refresh-button {
  cursor: pointer;
  border: 1px solid #333;
  border-radius: 0.25em;
  background-color: white;
  margin: 0.75em;
}

.refresh-spin {
  animation: spin 500ms linear infinite;
  pointer-events: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toast {
  background-color: lightblue;
}