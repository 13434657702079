div.Grid {
    width: 100%;
}

div.Grid div.grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

div.Grid .button {
    padding: 0;
}

div.Grid div.controls-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    margin-top: 0.5em;
}

div.Grid div.controls-container > * {
    flex: 1;
    flex-basis: auto;
}

div.Grid div.width-and-size-container, div.n-images-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.Grid div.width-and-size-container input {
    text-align: right;
}

div.Grid div.width-and-size-container label {
    margin-right: 0.5em;
    margin-left: 1em;
    padding: 10px;
}

div.Grid div.width-and-size-container div.change-grid-width-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid black;
    margin-right: 0.5em;
    background-color: #EEE;
}

div.Grid div.width-and-size-container div.change-image-size-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid black;
    background-color: #EEE;
}

div.Grid div.width-and-size-container div.change-image-size-container button.change-image-size {
    margin-left: 0.5em;
    height: 2em;
    width: 2em;
}

div.Grid div.header-container {
    display: block;
}

div.Grid div.header-container div.controls-container {
    justify-content: center;
}

/* Button must be direct child to not affect the dropdown */
div.Grid div.header-container div.controls-container > button, div.label-filename-container > button {
    max-width: 30em;
    min-height: 3em;
}

div.Grid div.label-filename-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em;
    margin-top: 0.5em;
}

div.Grid div.label-filename-container > * {
    flex: 1;
    flex-basis: auto;
}

div.Grid div.weed-pressure-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

div.Grid form.weed-pressure-form {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 10px;
    background-color: #EEE;
}

div.Grid form.weed-pressure-form label.weed-pressure-radio {
    margin: 10px 0;
    border: 1px solid black;
    padding: 10px;
    background-color: lightyellow;
}

div.Grid form.weed-pressure-form button.weed-pressure-submit {
    width: 50%;
    margin: 10px 0;
    background-color: lightyellow;
}

div.Grid div.refresh-container {
    flex: 1;
    display: flex;
    justify-content: left;
    margin-top: 0.5em;
}

div.Grid div.filter-container {
    flex: 3;
    display: flex;
    justify-content: right;
}

div.Grid div.checkbox-container {
    flex: 1;
    position: relative;
    margin-right: 10px;
}

div.Grid div.checkbox-container label {
    display: block;
    text-align: center;
    line-height: 150%;
}

div.Grid div.checkbox-container input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 5px;
    border: 2px solid #555; 
}

div.Grid div.show-previously-excluded input[type="checkbox"]:checked {
    background: pink;
}

div.Grid div.show-completed input[type="checkbox"]:checked {
    background: lightgreen;
}

div.Grid div.show-new-tiles-only input[type="checkbox"]:checked {
    background: gold;
}