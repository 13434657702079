.Analytics {
    text-align: center;
    height: 400;
    width: "100%";
}

div.Analytics img.nav-link-icon {
    max-width: 30%;
}

div.Analytics div.controls-container {
    display: flex;
    justify-content: center;
}

div.Analytics div.refresh-container {
    flex: 2;
    display: flex;
    justify-content: left;
}

div.Analytics div.filter-container {
    flex: 3;
    display: flex;
    justify-content: right;
}

div.Analytics div.checkbox-container {
    flex: 1;
    position: relative;
    margin-right: 10px;
}

div.Analytics div.checkbox-container label {
    display: block;
    text-align: center;
    line-height: 150%;
}

div.Analytics div.checkbox-container input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 5px;
    border: 2px solid #555; 
}

div.Analytics div.show-only-labeling-jobs input[type="checkbox"]:checked {
    background: lightblue;
}

div.Analytics div.show-fulfilled input[type="checkbox"]:checked {
    background: lightgreen;
}

div.Analytics div.show-abandoned input[type="checkbox"]:checked {
    background: pink;
}