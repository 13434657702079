div.ExternalHomepage div.outsourcing-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

div.ExternalHomepage div.outsourcing-container > * {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    width: 50%;
    border: 1px solid black;
    background-color: lightgray;
}

div.ExternalHomepage div.refresh-container {
    display: flex;
    justify-content: left;
}

div.ExternalHomepage div.start-annotating-container {
    align-items: center;
    margin-top: 20px;
}

div.ExternalHomepage div.start-annotating-container button {
    margin: 0 10px;
    border: 0.5px solid black;
    background-color: lightgreen;
    padding: 10px;
    font-size: 1.2em;
}